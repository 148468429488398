import { apiVersion } from './api-version';
import { endpoints } from './endpoints';

const releaseUrl = 'https://api.release.ats.uhigherdev.com';

export const environment = {
  appVersion: require('../../package.json').version + '-r',
  environment: 'release',
  production: false,
  domain: 'release.ats.uhigherdev',
  mediaServerUrl: 'wcs.ats.uhigherdev.com',
  ...endpoints(releaseUrl, apiVersion)
};
